<template>
<main>
  <page-header>Learning Tree</page-header>
  <div v-if="node" class="page-wrapper" ref="node_wrapper">
    <div class="page-outer parents">
      <div class="page-inner parents">
        <div class="header">Parents</div>
        <div class="page-content list-group">
          <router-link v-for="parent in node.parents" class="list-group-item" :key="parent.id" :to="{ name: 'PageTree', params:{id: parent.id} }">{{parent.title}}</router-link>
        </div>
      </div>
    </div>
    <div class="page-outer node">
      <div class="page-inner node" v-if="node">
        <div class="header">{{node.title}}</div>
        <div class="page-content">
          <h2>Parents</h2>
            <p>
              <span v-for="parent in node.parents" :key="parent.id">
                <span class="badge">{{parent.id}}</span> {{parent.title}} <br>
              </span>
            </p>
          <h2>Children</h2>
            <p>
              <span v-for="child in node.children" :key="child.id">
                <span class="badge">{{child.id}}</span> {{child.title}} <br>
              </span>
            </p>
            <hr>
            <div v-html="wiki2html(node.note)"></div>
              <br>
            <p>
              <i>{{node.credit}}</i>
            </p>
            <div class="video-wrapper" v-for="video in node.videos" :key="video.id">
              <div class="embed-responsive embed-responsive-4by3">
                <iframe class="embed-responsive-item video" :src="`https://www.youtube.com/embed/${video.code}`" allowfullscreen></iframe>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="page-outer children">
      <div class="page-inner children">
        <div class="header">Children</div>
        <ul>
          <li v-for="child in node.children" :key="child.id"><router-link :to="{ name: 'PageTree', params:{id: child.id} }">{{child.title}}</router-link></li>
        </ul>
      </div>
    </div>
  </div>
  <section class="loading" v-else>
    LOADING
  </section>
  </main>
</template>

<script>
import pathwayzAPI from '@/pathwayzAPI.js'
import Wiki2HTML from '@/wiki2html.js'
const wiki2html = new Wiki2HTML()

export default {
  name: 'PageTree',
  data: () => {
    return {
      node: null,
      touch_start: null,
      offset_x: 0
    }
  },
  watch: {
    $route () {
      console.log('ID CHANGE')
      this.getNode()
      this.offset_x = 0
    }
  },
  methods: {
    async getNode () {
      const data = await pathwayzAPI.getNode(this.$route.params.id)
      console.log(data)
      this.node = data
      this.$nextTick(() => {
        console.dir(this.$refs.node_wrapper);
        this.$refs.node_wrapper.scrollTo(this.$refs.node_wrapper.scrollWidth/3, 0)
      })
    },
    wiki2html(wiki){
      return wiki2html.wiki2html(wiki)
    },
    nextNode () {
      if (this.node.children.length === 1) {
        this.$router.push({ name: 'PageTree', params: { id: this.node.children[0].id } })
      } else if (this.node.children.length) {
        this.$router.push({ name: 'PageChildren', params: { id: this.node.id, children: this.node.children } })
      } else {
        // Nothing happens
      }
    },
    previousNode () {
      if (this.node.parents.length === 1) {
        this.$router.push({ name: 'PageTree', params: { id: this.node.parents[0].id } })
      } else if (this.node.parents.length) {
        this.$router.push({ name: 'PageParents', params: { id: this.node.id, parents: this.node.parents } })
      } else {
        // Nothing happens
      }
    },
    touchStartHandler (event) {
      this.touch_start = {
        x: event.touches[0].screenX,
        y: event.touches[0].screenY
      }
    },
    touchMoveHandler (event) {
      if (this.touch_start === null) return
      const deltaX = this.touch_start.x - event.touches[0].screenX
      const windowWidth = window.innerWidth
      if (deltaX > 1) {
        //  Swipe left
        if (this.node.children.length === 0) return
        this.offset_x += (deltaX / windowWidth) * 100
        if (this.offset_x >= 100) this.offset_x = 100
      } else if (deltaX < 1) {
        //  Swipe right
        if (this.node.parents.length === 0) return
        this.offset_x += (deltaX / windowWidth) * 100
        if (this.offset_x <= -100) this.offset_x = -100
      }
      this.touch_start = {
        x: event.touches[0].screenX,
        y: event.touches[0].screenY
      }
    },
    touchEndHandler () {
      if (this.offset_x >= 50) {
        this.offset_x = 100
        this.touch_start = null
      } else if (this.offset_x <= -50) {
        this.offset_x = -100
        this.touch_start = null
      } else {
        this.offset_x = 0
        this.touch_start = null
      }
    }
  },
  created () {
    this.getNode()
  }
}
</script>

<style lang="css" scoped>
.page-wrapper {flex: 1;display:flex;flex-direction:row;position:relative;width:100%;overflow-x:scroll;scroll-snap-type:x mandatory;height:100%;;overflow-y:hidden; }
.page-wrapper>* {
  flex:0 0 100%;
  width:100%;
  overflow-x:hidden;
  scroll-snap-align: center;
}
.page-inner {height:100%;overflow-y:auto;}
.video-wrapper {margin-top:10px;margin-bottom:10px;}

.page-inner.node>.page-header {
  position:sticky;
  top:0;
}

.header {
  background-color:var(--secondary);
  padding:0.5rem;
  text-align:center;
}

img {
  max-width:100%;
}
</style>
