<template>
  <div class="page-wrapper" v-touch:swipe.left="returnNode">
    <div class="page-header">Children</div>
    <div class="page-content list-group">
      <router-link v-for="child in children" class="list-group-item" :key="child.id" :to="{ name: 'PageTree', params:{id: child.id} }">{{child.title}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageChildren',
  props: ['id', 'children'],
  methods: {
    returnNode () {
      this.$router.push({ name: 'PageTree', params: { id: this.id } })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
