<template lang="html">
  <div>
    <button @click="flashcards">Flashcards</button>
  </div>
</template>

<script>
export default {
  name: 'PageRevise',
  methods: {
    flashcards () {
      this.$router.push({ name: 'PageFlashcardTagCategories', params: {} })
    }
  }
}
</script>

<style lang="css">
</style>
