<template>
  <div class="page-wrapper">
    <div class="page-header">Parents</div>
    <div class="page-content list-group">
      <router-link v-for="parent in parents" class="list-group-item" :key="parent.id" :to="{ name: 'PageTree', params:{id: parent.id} }">{{parent.title}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageParents',
  props: ['parents']
}
</script>

<style lang="css" scoped>

</style>
