<template>
  <main>
    <section class="learn-page-wrapper" v-if="selected === 'home'">
    <page-header>Learning with Pathwayz</page-header>

      <ul class="topic-selector">
        <li v-on:click="selected = 'biology'">
          <img src="https://www.pathwayz.org/images/topic-icon/biology.png" alt="Biology">
          Biology
        </li>
        <li v-on:click="selected = 'chemistry'">
          <img src="https://www.pathwayz.org/images/topic-icon/chemistry.png" alt="Chemistry">
          Chemistry
        </li>
        <li v-on:click="selected = 'physics'">
          <img src="https://www.pathwayz.org/images/topic-icon/physics.png" alt="Physics">
          Physics
        </li>
      </ul>
    </section>
    <section class="learn-page-wrapper" v-if="selected === 'biology'">
    <page-header>Biology</page-header>
      <ul>
        <li><router-link class="list-group-item" to="/learn/tree/28">Animals</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/49">Biochemistry</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/58">Biotechnology</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/59">Botany (Plants)</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/73">Cells</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/134">Ecology</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/162">Evolution</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/214">Human Evolution</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/198">Genetics</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/213">Human Biology</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/262">Microbiology</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/608">Taxonomy (Classification)</router-link></li>
        <span class="list-group-item" v-on:click="selected = 'home'">Back</span>
      </ul>
    </section>
    <section class="learn-page-wrapper" v-if="selected === 'chemistry'">
    <page-header>Chemistry</page-header>
      <ul>
        <li><router-link class="list-group-item" to="/learn/tree/4">Acids &amp; Bases</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/34">Matter &amp; Atoms</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/78">Chemical Reactions</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/565">Organic Chemistry</router-link></li>
        <span class="list-group-item" v-on:click="selected = 'home'">Back</span>
      </ul>

    </section>
    <section class="learn-page-wrapper" v-if="selected === 'physics'">
      
      <page-header>Physics</page-header>
      <ul>
        <li><router-link class="list-group-item" to="/learn/tree/902">Alternating Current</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/138">Electricity</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/901">Electromagnetism &amp; Induction</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/994">Fields</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/255">Mechanics (Motion)</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/452">Waves</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/708">Modern Physics</router-link></li>
        <li><router-link class="list-group-item" to="/learn/tree/900">Thermal Physics</router-link></li>
        <span class="list-group-item" v-on:click="selected = 'home'">Back</span>
      </ul>
    </section>
  </main>
</template>

<script>
export default {
  name: 'PageLearn',
  methods: {
    navigateHome () {
      this.$router.push({ name: 'PageHome', params: {} })
    }
  },
  data: () => {
    return {
      selected: 'home'
    }
  }
}
</script>

<style lang="css" scoped>
.learn-page-wrapper {
  display:flex;
  flex-direction:column
}

.topic-selector {
  height:100%;
  display:flex;
  flex-direction: column;
  gap:1rem;
}
.topic-selector>* {
  display:flex;
  gap:2rem;
  align-items: center;
  justify-content: center;
  font-size:2rem;
  border:solid 5px var(--primary);
  margin:1rem;
}
.topic-selector img {
  height:20vh;
}
.page-header {
  flex: 1;
  text-align:center;
  padding: 20px;
  font-family: 'Cabin', sans-serif;
  font-weight:600;
  font-size:6vmin;
  background-color:#e8f0e3;
  position: relative;
}
.list-group {
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-size:5vmin;
}
svg {
  position:absolute;
  top: 0;
  left: 0;
  height:100% !important;
  width: auto !important;
  padding: 10px;
}
</style>
