<template>
<main>
  <div class="flashcard-wrapper" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend">
    <div class="flashcard" :style="card_flip_style">
      <div class="question">
        <div class="position">
          <span class="current_index">{{current_index + 1}}</span>
          <span class="total_count">{{flashcards.flashcards.length}}</span>
        </div>
        <!-- {{current_index + 1}} -->
        {{flashcards.flashcards[current_index].question}}
        <img :src="flashcards.flashcards[current_index].image" alt="">
      </div>
      <div class="answer">
        <div class="position">
          <span class="current_index">{{current_index + 1}}</span>
          <span class="total_count">{{flashcards.flashcards.length}}</span>
        </div>
        {{flashcards.flashcards[current_index].answer}}
      </div>
    </div>
  </div>
  <nav>
        <button @click="navigateHome">
          Home
        </button>
        <button @click="previousCard" :disabled="current_index === 0">
          Prev
        </button>
        <button @click="nextCard" :disabled="current_index === flashcards.flashcards.length - 1">
          Next
        </button>
        <button @click="increaseRotation">
          Flip
        </button>
  </nav>
  </main>
</template>

<script>
import FlashcardCollection from '@/FlashcardCollection.js'
export default {
  name: 'PageFlashcards',
  data () {
    return {
      flashcards: null,
      current_index: 0,
      card_rotation: 0,
      card_rotation_start: 0,
      card_slide: 0,
      card_slide_start: 0,

      touch_start_x: null,
      touch_start_y: null,
      touch_curr_x: null,
      touch_curr_y: null,
      touch_prev_time: null,
      touch_last_time: null,
      tween_rotation_start: null,
      tween_slide_start: null,
      tween_distance: null,
      card_has_changed: false
    }
  },
  computed: {
    card_flip_style () {
      return `transform:rotateY(${180 * this.card_rotation}deg) translateY(calc(100% * ${this.card_slide}))`
    }
  },
  created () {
    this.flashcards = new FlashcardCollection(3)
  },
  methods: {
    touchstart(event){
      this.touch_start_x = event.changedTouches[0].pageX
      this.touch_start_y = event.changedTouches[0].pageY
      this.touch_last_time = Date.now()
      this.card_rotation_start = this.card_rotation
      this.card_slide_start = this.card_slide
      this.card_has_changed = false
    },
    touchmove(event){
      event.preventDefault() // prevent scroll events
      this.touch_curr_x = event.changedTouches[0].pageX
      this.touch_curr_y = event.changedTouches[0].pageY
      this.touch_prev_time = this.touch_last_time
      this.touch_last_time = Date.now()
      // Determine swipe vertical or horizontal
      if(Math.abs(this.touch_curr_x - this.touch_start_x) >= Math.abs(this.touch_curr_y - this.touch_start_y)){
        // console.log('Horizontal')
        this.card_rotation = this.card_rotation_start + (this.touch_curr_x - this.touch_start_x) / event.view.innerWidth / 0.6 //60% of screen width is a 180degree card flip
        this.card_slide = this.card_slide_start
      } else {
        // console.log('Vertical')
        this.card_rotation = this.card_rotation_start
        this.card_slide = this.card_slide_start + (this.touch_curr_y - this.touch_start_y) / event.view.innerHeight / 0.9 //60% of screen height is a 100% card slide
      }
    },
    touchend(){
      if(Math.abs(this.touch_curr_x - this.touch_start_x) >= Math.abs(this.touch_curr_y - this.touch_start_y)){
        // console.log('Horizontal')
        this.tween_rotation_start = this.card_rotation
        if(this.touch_curr_x - this.touch_start_x >= 0) {
          this.tween_distance = Math.ceil(this.card_rotation) - this.card_rotation
        } else {
          this.tween_distance = Math.floor(this.card_rotation) - this.card_rotation
        }
        this.tween_card_rotation()
      } else {
        // console.log('Vertical')
        this.tween_slide_start = this.card_slide
        if(this.touch_curr_y - this.touch_start_y >= 0) {
          this.tween_distance = Math.ceil(this.card_slide) - this.card_slide
        } else {
          this.tween_distance = Math.floor(this.card_slide) - this.card_slide
        }
        this.tween_card_slide()
      }
    },
    tween_card_rotation(){
      const percentage = (Date.now() - this.touch_last_time) / (this.touch_last_time - this.touch_prev_time) * 0.1
      this.card_rotation = this.tween_rotation_start + percentage * this.tween_distance
      if(percentage < 1) {
        requestAnimationFrame(this.tween_card_rotation.bind(this))
      } else {
        // lock it
        this.card_rotation = Math.round(this.card_rotation)
      }
    },
    tween_card_slide(){
      const percentage = (Date.now() - this.touch_last_time) / (this.touch_last_time - this.touch_prev_time) * 0.1
      if(percentage < 1) {
        this.card_slide = this.tween_slide_start + percentage * this.tween_distance
        requestAnimationFrame(this.tween_card_slide.bind(this))
      } else {
        // lock it
        this.card_slide = Math.round(this.card_slide)
        if(this.card_slide === 1) {
          this.previousCard()
          this.card_slide = 0
        } else if(this.card_slide === -1) {
          this.nextCard()
          this.card_slide = 0
        }
        // change the card
      }
    },
    navigateHome () {
      this.$router.push({ name: 'PageHome', params: {} })
    },
    showAnswer () {
      if (Math.abs(this.card_rotation) % 2 === 0) {
        this.card_rotation -= 1
      }
    },
    showQuestion () {
      if (Math.abs(this.card_rotation) % 2 === 1) {
        this.card_rotation += 1
      }
    },
    swipeHandler (direction) {
      switch (direction) {
        case 'top':
          this.nextCard()
          break
        case 'bottom':
          this.previousCard()
          break
        case 'left':
          this.decreaseRotation()
          break
        case 'right':
          this.increaseRotation()
          break
      }
    },
    decreaseRotation () {
      this.card_rotation = this.card_rotation - 1
    },
    increaseRotation () {
      // this.card_rotation = this.card_rotation + 1
      this.touch_prev_time = Date.now() - 50
      this.touch_last_time = Date.now()
      this.tween_rotation_start = this.card_rotation = 0.1
      this.tween_distance = 0.9
      this.tween_card_rotation()

    },
    previousCard () {
      if (this.current_index !== 0) {
        this.current_index -= 1
        this.card_rotation = 0
      }
    },
    nextCard () {
      if (this.current_index < this.flashcards.flashcards.length - 1) {
        this.current_index += 1
        this.card_rotation = 0
      } else {
        this.flashcards.shuffle()
        this.current_index = 0
        this.card_rotation = 0
      }
      console.log(this.current_index)
    }
  }
}
</script>

<style scoped>
main {
  flex:1;
  display:flex;
}
nav {
  position:absolute;
  background-color:rgba(0,0,0,0.5);
  padding:0.25rem;
  border-radius:2rem;
  bottom:0.25rem;
  left:0.25rem;
  right:0.25rem;
  display:flex;
  gap:0.25rem;
}
nav>button {
  background-color:rgba(0,0,0,0.75);
  color:#fff;
  flex:1 0 0;
  border:none;
  height:3rem;
  border-radius:2rem;
  text-transform: uppercase;
  font-family:inherit;
}
nav>button[disabled] {
  opacity:0.5;
}

.position {
  position:absolute;
  top:1rem;
  right:1rem;
  opacity:0.5;
  font-size:0.7rem;
}
.position .current_index::after {
  content: ' of ';
}

.flashcard-wrapper {
  display: flex;
  flex: 1;
  perspective:200vw;
  perspective-origin: 50% 50%;
  background-color: #666;
  overflow:hidden;
}
.flashcard {
  position:relative;
  flex: 1;
  /*transition: transform 0.5s ease-out;*/
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  user-select: none;
}
.flashcard::before {
  content: "";
  display:block;
  width:9px;
  background-color:grey;
  position:absolute;
  top:0.25rem;
  bottom:4rem;
  left:0px;
  transform:rotateY(-90deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border:solid 0.25rem rgba(0,0,0,0.75);
}
.flashcard::after {
  content: "";
  display:block;
  width:9px;
  background-color:grey;
  position:absolute;
  top:0.25rem;
  bottom:4rem;
  right:0px;
  transform:rotateY(90deg);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border:solid 0.25rem rgba(0,0,0,0.75);
}
.flashcard .question {
  position:absolute;
  top:0.25rem;
  bottom:4rem;
  left:0.25rem;
  right:0.25rem;
  display:flex;
  align-items:center;
  justify-content: center;
  text-align:center;
  line-height:1.6;
  
  transform:translateZ(5px);
  background-color: var(--primary);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  padding:20px;
  flex-direction:column;
  border-radius:2rem;
  overflow: hidden;
  border:solid 0.25rem rgba(0,0,0,0.75);
}

.flashcard .question::before {
  content:'Q';
  position:absolute;
  bottom:1rem;
  left:1rem;
  font-size:10rem;
  line-height:1em;
  opacity:0.1;
}
.flashcard .answer {
  position:absolute;
  top:0.25rem;
  bottom:4rem;
  left:0.25rem;
  right:0.25rem;
  display:flex;
  align-items:center;
  justify-content: center;
  text-align:center;
  font-size: 3vmax;
  
  transition:none;
  transform:translateZ(-5px) rotateY(180deg);
  background-color: var(--secondary);
  /* perspective:100px; */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  padding:20px;
  border-radius:2rem;
  overflow: hidden;
  border:solid 0.25rem rgba(0,0,0,0.75);
}
.flashcard .answer::before {
  content:'A';
  position:absolute;
  bottom:1rem;
  left:1rem;
  font-size:10rem;
  line-height:1em;
  opacity:0.1;
}

.flashcard img {
  margin:10px;
  max-width: 60vw;
  max-height: 60vh;
  width:auto;
  height:auto;
  display:block;
}

.fa.fa-chevron-circle-left  {
  color: white;
  z-index: 1000;
  transform: translateZ(5px);
  position: absolute;
  font-size: 10vmax;
  opacity: 0.5;
  margin-left: 10px;
  margin-bottom: 10px;
  bottom: 0;
  left: 0;
}

.fa.fa-chevron-circle-right {
  color: white;
  z-index: 1000;
  transform: translateZ(5px);
  position: absolute;
  font-size: 10vmax;
  opacity: 0.5;
  margin-right: 10px;
  margin-bottom: 10px;
  bottom: 0;
  right: 0;
}

.fa.fa-refresh {
  color: white;
  z-index: 1000;
  transform: translateZ(5px);
  position: absolute;
  font-size: 10vmax;
  opacity: 0.5;
  margin-right: 10px;
  margin-top: 10px;
  top: 0;
  right: 0;
}

.answer .fa.fa-refresh {
  transform: translateZ(-5px);
  margin-top: 10px;
  margin-right: 10px;
}

.fa.fa-home {
  color: white;
  z-index: 1000;
  transform: translateZ(5px);
  position: absolute;
  font-size: 10vmax;
  opacity: 0.5;
  margin-left: 10px;
  margin-top: 10px;
  top: 0;
  left: 0;
}

.fa:active {
  opacity: 0.75;
}
</style>
