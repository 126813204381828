import FlashcardModel from '@/FlashcardModel.js'
import FlashcardData from '@/assets/flashcards.json'

class FlashcardCollection {
  constructor (quantity) {
    if (!quantity || !Number.isInteger(quantity)) throw new Error('Quantity must be an Integer')
    this.attributes = {
      name: "",
      image: "",
      flashcards: []
    }
    // for (let i = 0; i < quantity; i++) {
    //   this.attributes.flashcards.push(new FlashcardModel())
    // }
    this.attributes.name = FlashcardData.name
    this.attributes.image = FlashcardData.image

    for (const flashcard of FlashcardData.flashcards) {
      this.attributes.flashcards.push(new FlashcardModel(flashcard))
    }
    this.shuffle()
  }

  get flashcards () {
    return this.attributes.flashcards
  }
  get name () {
    return this.attributes.name
  }
  get image () {
    return this.attributes.image
  }

  shuffle () {
    let o = this.attributes.flashcards
    for (let j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
  }
}

export default FlashcardCollection
