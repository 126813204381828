class Flashcard {
  constructor (flashcard) {
    this.attributes = {
      question: flashcard.question,
      image: flashcard.image,
      answer: flashcard.answer
    }
  }

  get question () {
    return this.attributes.question
  }

  get answer () {
    return this.attributes.answer
  }

  get image () {
    return this.attributes.image
  }
}

export default Flashcard
