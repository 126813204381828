/*
@author: remy sharp / http://remysharp.com
@url: http://remysharp.com/2008/04/01/wiki-to-html-using-javascript/
@license: Creative Commons License - ShareAlike http://creativecommons.org/licenses/by-sa/3.0/
@version: 1.0

Can extend String or be used stand alone - just change the flag at the top of the script.
*/

/* eslint-disable */
export default class Wiki2HTML {

  // Create Base64 Object
  Base64 = {
    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    encode: function (e) {
      var t = "";
      var n, r, i, s, o, u, a;
      var f = 0;
      e = Base64._utf8_encode(e);
      while (f < e.length) {
        n = e.charCodeAt(f++);
        r = e.charCodeAt(f++);
        i = e.charCodeAt(f++);
        s = n >> 2;
        o = (n & 3) << 4 | r >> 4;
        u = (r & 15) << 2 | i >> 6;
        a = i & 63;
        if (isNaN(r)) {
          u = a = 64
        } else if (isNaN(i)) {
          a = 64
        }
        t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a)
      }
      return t
    },
    decode: function (e) {
      var t = "";
      var n, r, i;
      var s, o, u, a;
      var f = 0;
      e = e.replace(/[^A-Za-z0-9\+\/\=]/g, "");
      while (f < e.length) {
        s = this._keyStr.indexOf(e.charAt(f++));
        o = this._keyStr.indexOf(e.charAt(f++));
        u = this._keyStr.indexOf(e.charAt(f++));
        a = this._keyStr.indexOf(e.charAt(f++));
        n = s << 2 | o >> 4;
        r = (o & 15) << 4 | u >> 2;
        i = (u & 3) << 6 | a;
        t = t + String.fromCharCode(n);
        if (u != 64) {
          t = t + String.fromCharCode(r)
        }
        if (a != 64) {
          t = t + String.fromCharCode(i)
        }
      }
      t = Base64._utf8_decode(t);
      return t
    },
    _utf8_encode: function (e) {
      e = e.replace(/\r\n/g, "\n");
      var t = "";
      for (var n = 0; n < e.length; n++) {
        var r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r)
        } else if (r > 127 && r < 2048) {
          t += String.fromCharCode(r >> 6 | 192);
          t += String.fromCharCode(r & 63 | 128)
        } else {
          t += String.fromCharCode(r >> 12 | 224);
          t += String.fromCharCode(r >> 6 & 63 | 128);
          t += String.fromCharCode(r & 63 | 128)
        }
      }
      return t
    },
    _utf8_decode: function (e) {
      var t = "";
      var n = 0;
      var r = c1 = c2 = 0;
      while (n < e.length) {
        r = e.charCodeAt(n);
        if (r < 128) {
          t += String.fromCharCode(r);
          n++
        } else if (r > 191 && r < 224) {
          c2 = e.charCodeAt(n + 1);
          t += String.fromCharCode((r & 31) << 6 | c2 & 63);
          n += 2
        } else {
          c2 = e.charCodeAt(n + 1);
          c3 = e.charCodeAt(n + 2);
          t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63);
          n += 3
        }
      }
      return t
    }
  }

  constructor() {
    // think its all static
  }

  // utility function to check whether it's worth running through the wiki2html
  iswiki(s) {
    if (extendString) {
      s = this;
    }

    return !!(s.match(/^[\s{2} `#\*='{2}]/m));
  }

  // Regex the content and change wiki code to HTML
  wiki2html(str) {

    // Search for LaTeX code
    var arr = str.split('$$');

    // If LaTeX code exists, then run list on those items only
    if (1 < arr.length && 0 != arr.length % 2) {
      console.log('Notes contain LaTeX');

      for (var i = 0; i < arr.length; i += 2) {
        arr[i] = arr[i].wiki2html();
      }
      return arr.join('$$');
    }

    // Notes contain a LaTeX error - missing closing tag
    if (1 < arr.length && 0 == arr.length % 2)
      console.log('Notes contain a LaTeX error - missing closing tag');

    // lists need to be done using a function to allow for recusive calls
    function list(str) {
      return str.replace(/(?:(?:(?:^|\n)[\*#].*)+)/g, function (m) { // *#
        var type = m.match(/(^|\n)#/) ? 'OL' : 'UL';
        // strip first layer of list
        m = m.replace(/(^|\n)[\*#][ ]{0,1}/g, "$1");
        m = list(m);
        return '<' + type + '><li>' + m.replace(/^\n/, '').split(/\n/).join('</li><li>') + '</li></' + type + '>';
      });
    }

    // Formatting
    return list(str

      /*
      .replace(/(?:^|\n$$)(?:$$)/g, function (m, l) { // put any latex on a single line - FINISH
      return '<hr>';
    })
    */

      .replace(/----/g, function (m, l) { // italic
        return '<hr>';
      })

      .replace(/\n\n/g, function (m, l) { // line break
        return "\n\n<br>\n\n";
      })

      /* BLOCK ELEMENTS */
      .replace(/(?:^|\n+)([^\s\[# =\*<].+)(?:\n+|$)/gm, function (m, l) { // Paragraphs - previous line is blank
        if (l.match(/^\^+$/)) return l;
        return "\n<p>" + l + "</p>\n";
      })

      .replace(/(?:^|\n)[ ]{2}(.*)+/g, function (m, l) { // blockquotes - start line with '  '
        if (l.match(/^\s+$/)) return m;
        return '<blockquote>' + l + '</blockquote>';
      })

      .replace(/((?:^|\n)[ ]+.*)+/g, function (m) { // code - start line with ' '
        if (m.match(/^\s+$/)) return m;
        return '<pre>' + m.replace(/(^|\n)[ ]+/g, "$1") + '</pre>';
      })

      .replace(/(?:^|\n)([=]+)(.*)\1/g, function (m, l, t) { // headings
        return '<h' + (l.length + 1) + '>' + t + '</h' + (l.length + 1) + '>';
      })

      .replace(/'''(.*?)'''/g, function (m, l) { // bold
        return '<strong>' + l + '</strong>';
      })

      .replace(/''(.*?)''/g, function (m, l) { // italic
        return '<em>' + l + '</em>';
      })

      .replace(/\^(.*?)\^/g, function (m, l) { // superscript
        return '<sup>' + l + '</sup>';
      })

      .replace(/\~(.*?)\~/g, function (m, l) { // subscript
        return '<sub>' + l + '</sub>';
      })

      .replace(/[\[]image:(http.*)[!\]]/g, function (m, l) { // image link
        var p = l.replace(/[\[]image:|[\]]/g, '').split(/ /);
        var link = p.shift();
        link = link.replace('http://', 'https://');

        var ext = '';
        if (link.match(/\.gif/))
          ext = '.gif';

        // Enable URL encoding
        link = 'https://www.pathwayz.org/Node/Image/url/' + btoa(link);

        return '<img class="img-fluid" src="' + link + ext + '" alt="pathwayz-' + (p.length ? p.join(' ') : '') + '">';
      })

      .replace(/[\[](http.*)[!\]]/g, function (m, l) { // external link
        var p = l.replace(/[\[\]]/g, '').split(/ /);
        var link = p.shift();
        link = link.replace(/https:\/\/www.pathwayz.org\/Tree\/[\s\S]*#!o(\d)/i, '/learn/tree/$1')
        return '<a href="' + link + '">' + (p.length ? p.join(' ') : link) + '</a>';
      })

      .replace(/\[\[(.*?)\]\]/g, function (m, l) { // internal link or image
        var p = l.split(/\|/);
        var link = p.shift();

        if (link.match(/^Image:(.*)/)) {
          // no support for images - since it looks up the source from the wiki db :-(
          return m;
        } else {
          return '<a href="' + link + '">' + (p.length ? p.join('|') : link) + '</a>';
        }
      })

    );
  }
}