<template lang="html">
  <div class="tag-category-wrapper">
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-window-restore"></i>
          <label>Packs</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-shopping-bag"></i>
          <label>Subjects</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-hashtag"></i>
          <label>Topics</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-file"></i>
          <label>Exams</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-code"></i>
          <label>Placeholder</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-archive"></i>
          <label>Placeholder</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content">
          <i class="fa fa-globe"></i>
          <label>All</label>
        </div>
      </div>
      <div class="tag-category">
        <div class="tag-category-content" @click="navigatePurchase">
          <i class="fa fa-shopping-cart"></i>
          <label>Purchase</label>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PageTagCategories',
  methods: {
    navigatePurchase () {
      this.$router.push({ name: 'PageFlashcardPurchase', params: {} })
    },
    navigateSelect () {
      this.$router.push({ name: 'PageFlashcardSelect', params: {} })
    }
  }
}
</script>

<style lang="css" scoped>
.tag-category-wrapper {
  display:grid;
  grid-template-columns: 1fr 1fr;
}
.tag-category {
  flex: 1;
  display:flex;
  justify-content: center;
  align-items: center;
}
.tag-category label {
  font-weight:600;
  color:rgba(255,255,255,0.69);
  display:block;
  text-align:center;
  margin-bottom:0px;
}
.fa {
  font-size:5em;
  color:rgba(255,255,255,0.69);
}
.tag-category-row:nth-child(1) .tag-category:nth-child(1) {background-color: var(--blue)}
.tag-category-row:nth-child(1) .tag-category:nth-child(2) {background-color: var(--pink)}
.tag-category-row:nth-child(2) .tag-category:nth-child(1) {background-color: var(--red)}
.tag-category-row:nth-child(2) .tag-category:nth-child(2) {background-color: var(--cyan)}
.tag-category-row:nth-child(3) .tag-category:nth-child(1) {background-color: var(--green)}
.tag-category-row:nth-child(3) .tag-category:nth-child(2) {background-color: var(--yellow)}
.tag-category-row:nth-child(4) .tag-category:nth-child(1) {background-color: var(--orange)}
.tag-category-row:nth-child(4) .tag-category:nth-child(2) {background-color: var(--purple)}
</style>
