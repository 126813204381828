<template lang="html">
  <main class='page-wrapper about'>
    <page-header>About Pathwayz</page-header>

    <p>
      Pathwayz is a collabrative project. <br>
      Pathwayz is a community of Science teachers and their students. <br>
      Every teacher and student helps to develop Pathwayz just by using it!
      Contribute by adding videos, rating them and suggesting improvements.
      All services which are currently free to use will remain that way for all time.
    </p>
  </main>
</template>

<script>
export default {
  name: 'PageAbout',
  methods: {
    navigateHome () {
      this.$router.push({ name: 'PageHome', params: {} })
    }
  }
}
</script>

<style lang="css" scoped>
.page-header {
  position: relative;
}
svg {
  position:absolute;
  top: 0;
  left: 0;
  height:100% !important;
  width: auto !important;
  padding: 10px;
}
</style>
