import { createRouter, createWebHistory } from 'vue-router'

import PageLogin from '@/views/page-login'
import PageHome from '@/views/page-home'
import PageLearn from '@/views/page-learn'
import PageTree from '@/views/tree/page-tree'
import PageParents from '@/views/tree/parents/page-parents'
import PageChildren from '@/views/tree/children/page-children'
import PageRevise from '@/views/page-revise'
import PageFlashcards from '@/views/flashcards/page-flashcards'
import PageFlashcardTagCategories from '@/views/flashcards/tag-categories/page-tag-categories'
import PageFlashcardPurchase from '@/views/flashcards/tag-categories/purchase/page-purchase'
import PageFlashcardSelect from '@/views/flashcards/tag-categories/select/page-select'
import PagePurchase from '@/views/flashcards/page-purchase'
import PageAbout from '@/views/page-about'
import PageDonate from '@/views/page-donate'



// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const routes = [
    {
      path: '/',
      name: 'PageLogin',
      component: PageLogin
    },
    {
      path: '/home',
      name: 'PageHome',
      component: PageHome
    },
    {
      path: '/learn',
      name: 'PageLearn',
      component: PageLearn
    },
    {
      path: '/learn/tree/:id',
      name: 'PageTree',
      component: PageTree
    },
    {
      path: '/learn/tree/:id/parents',
      name: 'PageParents',
      component: PageParents,
      props: true
    },
    {
      path: '/learn/tree/:id/children',
      name: 'PageChildren',
      component: PageChildren,
      props: true
    },
    {
      path: '/revise',
      name: 'PageRevise',
      component: PageRevise,
      props: true
    },
    {
      path: '/purchase',
      name: 'PagePurchase',
      component: PagePurchase,
      props: true
    },
    {
      path: '/revise/flashcards',
      name: 'PageFlashcards',
      component: PageFlashcards,
      props: true
    },
    {
      path: '/revise/flashcards/categories',
      name: 'PageFlashcardTagCategories',
      component: PageFlashcardTagCategories
    },
    {
      path: '/revise/flashcards/purchase',
      name: 'PageFlashcardPurchase',
      component: PageFlashcardPurchase,
      props: true
    },
    {
      path: '/revise/flashcards/select',
      name: 'PageFlashcardSelect',
      component: PageFlashcardSelect,
      props: true
    },
    {
      path: '/about',
      name: 'PageAbout',
      component: PageAbout,
      props: true
    },
    {
      path: '/donate',
      name: 'PageDonate',
      component: PageDonate,
      props: true
    }
  ]


const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
