export function groupArray (array, prop) {
  return array.reduce(function (groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}

export function sortObject (object) {
  let objectArray = []
  for (let key in object) {
    objectArray.push({
      name: key,
      value: object[key]
    })
  }
  return objectArray.sort((a, b) => {
    return a.name > b.name
  })
}
