<template>
    <header>
        <router-link :to="{ name: 'PageHome' }">
            <svg width="2rem" height="2rem" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
        </router-link>
        <div><slot></slot></div>
        <router-link :to="{ name: 'PageLogin' }">
            <svg width="2rem" height="2rem" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
            </svg>
        </router-link>
    </header>
    <nav v-if="$slots.back">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </svg>
        <slot name="back"></slot>
    </nav>
</template>

<style scoped>
header {
    display:flex;
    width:100%;
    justify-content: center;
    align-items: center;
    background-color:var(--primary);
}
header > * {
    flex:1;
    text-align:center;
}
header > :first-child {
    flex:0 0 3rem;
}
header > :last-child {
    flex:0 0 3rem;
}

header a {
    color:inherit;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

nav {
    padding:0.5rem;
    display:flex;
    align-items: center;
    opacity:0.8;
}

nav >>> a {
    font-size:0.8rem;
    text-decoration: none;
    color:inherit;
}
</style>