<template>
  <main>
    <page-header>Donate to Pathwayz</page-header>

    <h2>Support us...</h2>
    <p>It costs money to keep Pathwayz running and even more to further the development of our software.</p>
    <p>Donate by purchasing our supporter products, buying us a coffee or sending us any generous amount.</p>
    <p>We'd like to acknowledge all those people who have supported us here - you're awesome!</p>
    <br class="clear-fix">

    <h2>Donate</h2>
    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <p class="text-center button-donate">
            <input type="hidden" name="cmd" value="_s-xclick">
            <input type="hidden" name="hosted_button_id" value="UM8N4KDXKXPVL">
            <input type="image" src="https://www.pathwayz.org/images/donate/buy-us-a-coffee.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
            <img alt="Donate by buying us a Coffee" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
    </p>
    </form>

    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <p class="text-center button-donate">
            <input type="hidden" name="cmd" value="_s-xclick">
            <input type="hidden" name="hosted_button_id" value="URD5XSQDWE4EU">
            <input type="image" src="https://www.pathwayz.org/images/donate/donate.png" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
            <img alt="Donate any amount" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
    </p>
    </form>
  </main>
</template>

<script>
export default {
  name: 'PageDonate',
  methods: {
    navigateHome () {
      this.$router.push({ name: 'PageHome', params: {} })
    }
  }
}
</script>

<style lang="css" scoped>
.page-header {
  position: relative;
}
svg {
  position:absolute;
  top: 0;
  left: 0;
  height:100% !important;
  width: auto !important;
  padding: 10px;
}
</style>
