<template>
  <main>
    <page-header>
      <template v-slot:default>Purchase</template>
      <template v-slot:back
        ><router-link :to="{ name: 'PageFlashcardSelect' }"
          >back to Flashcards</router-link
        ></template
      >
    </page-header>
    <section>
      <div></div>
      <div class="text-center">
        <svg style="width: 10rem; height: 10rem" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M11.19,2.25C10.93,2.25 10.67,2.31 10.42,2.4L3.06,5.45C2.04,5.87 1.55,7.04 1.97,8.05L6.93,20C7.24,20.77 7.97,21.23 8.74,21.25C9,21.25 9.27,21.22 9.53,21.1L16.9,18.05C17.65,17.74 18.11,17 18.13,16.25C18.14,16 18.09,15.71 18,15.45L13,3.5C12.71,2.73 11.97,2.26 11.19,2.25M14.67,2.25L18.12,10.6V4.25A2,2 0 0,0 16.12,2.25M20.13,3.79V12.82L22.56,6.96C22.97,5.94 22.5,4.78 21.47,4.36M11.19,4.22L16.17,16.24L8.78,19.3L3.8,7.29"
          />
        </svg>
        <h1>Example Flashcards</h1>
        <p><strong>$10.99</strong><small>NZD</small></p>
      </div>

      <div v-if="payment_status" class="payment_status">
        <p v-if="payment_status === 'failed'" class="alert-error">
          Payment Error, please try again
        </p>
        <p v-else-if="payment_status === 'complete'" class="alert-success">
          Payment Complete!
        </p>
        <p v-else class="alert-error">{{ payment_status }}</p>
      </div>

      <div v-else ref="payment_request_button">
        <button class="loading-payment-processor-button" disabled>
          Loading Payment Processor
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import PathwayzAPI from "@/pathwayzAPI.js";

export default {
  data() {
    return {
      stripe_loaded: false,
      payment_status: null,
    };
  },
  watch: {
    async stripe_loaded(current, previous) {
      if (!current || previous === current) return;

      console.log("Loading Stripe", process.env.VUE_APP_STRIPE_PUB_KEY);
      const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUB_KEY, {
        apiVersion: "2020-08-27",
      });

      console.log("Creating Payment Request");
      const paymentRequest = stripe.paymentRequest({
        country: "NZ",
        currency: "nzd",
        total: {
          label: "Example Flashcards",
          amount: 299,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      var elements = stripe.elements();
      var prButton = elements.create("paymentRequestButton", {
        paymentRequest: paymentRequest,
      });
      console.log("Ready");

      // Check the availability of the Payment Request API first.
      const canMakePaymentResponse = await paymentRequest.canMakePayment();
      if (canMakePaymentResponse) {
        console.log("Can Make Payment, mounting button", canMakePaymentResponse);
        prButton.mount(this.$refs.payment_request_button);
      } else {
        console.log("Cant make a payment");
        this.$refs.payment_request_button.innerHTML = `<span class="alert-error">There was a problem, please try again later</span>`;
        return;
      }

      /*
      Server Side:
      curl https://api.stripe.com/v1/payment_intents \
        -u sk_test_xLhH7sntJEJFllhPZwbGU0Sj: \
        -d "amount"=1099 \
        -d "currency"="usd"
        */

      console.log("Getting Server Payment Intent");
      const payment_intent = await PathwayzAPI.initiateStripePayment(
        "example_flashcards"
      );
      const clientSecret = payment_intent.client_secret;
      console.log(clientSecret);

      paymentRequest.on("paymentmethod", async (ev) => {
        console.log("Payment Method Event Handler");
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
        console.log("Payment Confirmation Complete");

        if (confirmError) {
          console.log("Payment Failed", confirmError);
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          this.payment_status = "error";
        } else {
          console.log("Payment Success");
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === "requires_action") {
            console.log("Requires action");
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret);
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              this.payment_status = "error";
              console.error(error);
            } else {
              console.log("Payment Succeeded");
              // The payment has succeeded.
              this.payment_status = "complete";
            }
          } else {
            console.log("Payment Complete");
            // The payment has succeeded.
            this.payment_status = "complete";
          }
        }
      });
    },
  },
  methods: {
    mountStripeApi() {
      if (document.getElementById("stripe-api-script")) {
        console.log("STRIPE IS ALREADY LOADED");
        this.stripe_loaded = true;
      } else {
        const element = document.createElement("script");
        element.addEventListener("load", () => {
          this.stripe_loaded = true;
        });
        element.setAttribute("id", "stripe-api-script");
        element.setAttribute("src", "https://js.stripe.com/v3/");
        document.getElementsByTagName("head")[0].appendChild(element);
      }
    },
  },
  mounted() {
    this.mountStripeApi();
  },
};
</script>

<style scoped>
main {
  flex: 1;
  display: flex;
  flex-direction: column;
}
section {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
small {
  font-size: 0.8em;
}

.items > li {
  display: flex;
  justify-content: space-between;
}

strong {
  font-weight: 600;
}

.loading-payment-processor-button {
  width: 100%;
  background-color: var(--primary);
  border: none;
  border-radius: 0.5rem;
  padding: 1rem;
  filter: grayscale(1);
}
</style>