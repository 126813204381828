<template>
  <main class="select-page-wrapper">
    <page-header>Select a Pack of Flashcards</page-header>
    <section v-for="group in pack_subject_list" :key="group.name">
      <div class="subject-header">
        {{ group.name }}
      </div>
      <article
        class="list-group-item p-1"
        :class="{ owned: pack.owned }"
        @click="navigateFlashcards(pack)"
        v-for="pack in group.value"
        :key="pack.name"
      >
        <div class="image-wrapper" v-html="pack.icon"></div>
        <div class="text-wrapper">
          <label class="mx-3">{{ pack.name }}</label>
        </div>
        <div>
          <svg
            v-if="pack.owned"
            style="width: 2rem; height: 2rem"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
            />
          </svg>
          <div class="purchaseable" v-else>
            <svg style="width: 2rem; height: 2rem" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z"
              />
            </svg>
            <strong>$2.99</strong>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>

<script>
import PackStorageAPI from "@/packStorageAPI.js";
const packStorageAPI = new PackStorageAPI();

export default {
  name: "PageFlashcardSelect",
  computed: {
    pack_subject_list() {
      return packStorageAPI.getPacks();
    },
  },
  methods: {
    navigateFlashcards(pack) {
      if (pack.owned) {
        this.$router.push({ name: "PageFlashcards", params: {} });
      } else {
        this.$router.push({ name: "PagePurchase", params: {} });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.select-page-wrapper {
  display: flex;
  flex-direction: column;
}
.list-group {
  flex: 1;
  font-family: "Roboto", sans-serif;
}
section {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
}
.subject-header {
  justify-content: center;
  background-color: var(--secondary);
  padding: 0.5em 1rem;
  text-align: center;
}
article {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;
}
article > .text-wrapper {
  flex: 1;
}
article svg {
  color: var(--secondary-700);
}
article.owned svg {
  color: var(--primary-700);
}
.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.list-group-item img {
  flex: 0 0;
  width: 80px;
  height: 80px;
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.list-group-item label {
  flex: 0;
  margin-bottom: 0px;
}

.purchaseable {
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}
.purchaseable strong {
  font-size:0.8rem;
}
</style>
