
const API_URL = 'https://www.pathwayz.org/api'

export default {
  async getNode (nodeID) {
    const response = await fetch(`${API_URL}/tree/${nodeID}`)
    if(response.status === 200){
      return await response.json()
    } else {
      throw new Error('Error fetching node')
    }
  },
  async initiateStripePayment (item) {
    const response = await fetch(`${API_URL}/payment/stripe/initiate?${item}`)
    if(response.status === 200){
      return await response.json()
    } else {
      throw new Error('Error initiating payment')
    }
  }
}
