<template>
  <main>
    <h1>Login</h1>
    <button class="btn btn-secondary" @click="continueAsGuest" >Continue as Guest</button>
  </main>
</template>

<script>
export default {
  name: 'PageLogin',
  methods: {
    continueAsGuest () {
      console.log('Continue As Guest')
      this.$router.push({ name: 'PageHome', params: {} })
    }
  }
}
</script>

<style lang="css">
</style>
